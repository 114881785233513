*,
*:before,
*:after {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.container-goal {
  margin: 0;
  background-color: #ffffff;
  border-radius: 8px;
  /* box-shadow: 20px 20px 40px rgba(60, 60, 150, 0.25); */
}

.circular-progress {
  position: relative;
  height: 130px;
  width: 130px;
  border-radius: 50%;
  display: grid;
  place-items: center;
}

.circular-progress:before {
  content: "";
  position: absolute;
  height: 84%;
  width: 84%;
  background-color: #CCF8FE;
  border-radius: 50%;
}

.circular-progress1 {
  position: relative;
  height: 130px;
  width: 130px;
  border-radius: 50%;
  display: grid;
  place-items: center;
}

.circular-progress1:before {
  content: "";
  position: absolute;
  height: 84%;
  width: 84%;
  background-color: #DAD7FE;
  border-radius: 50%;
}

.circular-progress2 {
  position: relative;
  height: 130px;
  width: 130px;
  border-radius: 50%;
  display: grid;
  place-items: center;
}

.circular-progress2:before {
  content: "";
  position: absolute;
  height: 84%;
  width: 84%;
  background-color: #FDFDAF;
  border-radius: 50%;
}

.circular-progress3 {
  position: relative;
  height: 130px;
  width: 130px;
  border-radius: 50%;
  display: grid;
  place-items: center;
}

.circular-progress3:before {
  content: "";
  position: absolute;
  height: 84%;
  width: 84%;
  background-color: #91D7E0;
  border-radius: 50%;
}

.value-container {
  position: relative;
  font-family: "Poppins", sans-serif;
  font-size: 26px;
  color: #231c3d;
}

.container-goal-acheivement {
  position: relative;
  max-width: 240px;
  width: 100%;
  background: #fff;
  margin: 0 15px;
  padding: 10px 20px;
  border-radius: 7px;
}
.container-goal-acheivement .skill-box {
  width: 100%;
  margin: 25px 0;
}
.skill-box .title {
  display: block;
  font-size: 14px;
  font-weight: 600;
  color: #333;
}
.skill-box .skill-bar {
  height: 8px;
  width: 100%;
  border-radius: 6px;
  margin-top: 6px;
  background: rgba(0, 0, 0, 0.1);
}
.skill-bar .skill-per {
  position: relative;
  display: block;
  height: 100%;
  width: 90%;
  border-radius: 6px;
  background: #4070f4;
  animation: progress 0.4s ease-in-out forwards;
  opacity: 0;
}
.skill-per.css {
  width: 70%;
  animation-delay: 0.1s;
}
.skill-per.javascript {
  width: 50%;
  animation-delay: 0.2s;
}
.skill-per.nodejs {
  width: 30%;
  animation-delay: 0.3s;
}
@keyframes progress {
  0% {
    width: 0;
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}
.skill-per .tooltip {
  position: absolute;
  right: -14px;
  top: -28px;
  font-size: 9px;
  font-weight: 500;
  color: #fff;
  padding: 2px 6px;
  border-radius: 3px;
  background: #4070f4;
  z-index: 1;
}
.tooltip::before {
  content: "";
  position: absolute;
  left: 50%;
  bottom: -2px;
  height: 10px;
  width: 10px;
  z-index: -1;
  background-color: #4070f4;
  transform: translateX(-50%) rotate(45deg);
}

@media only screen and (max-width: 888px) {
  .item-goal-acheivement {
    flex-direction: column;
    justify-content: center;
  }
  .flagicon {
    display: none !important;
  }
  .goal-text {
    margin-top: 10px;
  }
}
